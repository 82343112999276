<template>
  <div>
    <MappingSuggestions
      :popup-config="popupConfig"
      :edit-data="editData"
      :selected-sport-label="selectedSportLabel"
      mapping-type="EVENTS_MAPPING"
      :selected-feed="{ feed: selectedFeed }"
      @mapping-approved="handleMappingApproved"
      @close-popup="handleMappingApproved"
    />
  </div>
</template>

<script>
import MappingSuggestions from '@/components/mapping/common/MappingSuggestions';

export default {
  components: {
    MappingSuggestions,
  },
  props: {
    popupConfig: {
      type: Object,
      required: true,
    },
    editData: {
      type: Object,
      required: true,
    },
    selectedSportLabel: {
      type: String,
      required: true,
    },
    selectedFeed: {
      type: String,
      required: true,
    },
  },
  emits: {
    changeScreen: {
      type: String,
    },
    approveMappingDetails: {
    },
    closePopup: {},
  },
  setup(props, { emit }) {
    const handleMappingApproved = (message) => {
      if (message) {
        emit('approveMappingDetails');
      } else {
        emit('closePopup');
      }
    };

    return {
      handleMappingApproved,
    };
  },
};
</script>
