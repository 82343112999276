<template>
  <div class="player-mapping-suggestions">
    <MappingSuggestions
      :popup-config="popupConfig"
      :edit-data="playerEditData"
      :selected-sport-label="selectedSportLabel"
      :selected-feed="{ feed: playerMappingFeed }"
      mapping-type="PLAYERS_MAPPING"
      @mapping-approved="handleMappingApproved"
      @mapping-created="handleMappingApproved"
      @close-popup="handleMappingApproved"
    />
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount } from 'vue';
import { createPopupConfig } from '@/services/helpers/mappings-mapper';
import MappingSuggestions from '@/components/mapping/common/MappingSuggestions';

export default {
  components: {
    MappingSuggestions,
  },
  props: {
    editData: {
      type: Object,
      required: true,
    },
    selectedSportLabel: {
      type: String,
      required: true,
    },
    selectedFeed: {
      type: String,
      required: true,
    },
    selectedPlayer: {
      type: Object,
      required: true,
    },
  },
  emits: {
    changeScreen: {
      type: String,
    },
    loadMappingDetails: {
    },
    approvePlayerMapping: {
    },
    closePopup: {
    },
  },
  setup(props, { emit }) {
    const playerMappingFeed = computed(() => (props.selectedFeed === 'huddle' ? props.selectedFeed : props.editData.feed));
    const playerEditData = computed(() => ({
      feed: playerMappingFeed.value,
      row: {
        ...props.selectedPlayer,
        id: props.selectedPlayer.mappingId,
        name: props.selectedPlayer.extRef.split(', ').reverse().join(' '),
      },
    }));
    const popupConfig = computed(() => createPopupConfig({
      editData: playerEditData.value,
      mappingType: 'PLAYERS_MAPPING',
      selectedFeed: playerMappingFeed.value || 'HUDDLE',
    }));
    const handleMappingApproved = () => {
      emit('approvePlayerMapping');
    };

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') {
        emit('closePopup');
      }
    };
    onMounted(() => {
      document.addEventListener('keydown', onEscEventListener);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('keydown', onEscEventListener);
    });

    return {
      playerEditData,
      popupConfig,
      playerMappingFeed,
      handleMappingApproved,
    };
  },
};
</script>

<style lang="scss" scoped>
.player-mapping-suggestions {
}
</style>
