<template>
  <teleport to="body">
    <div
      class="mapping-popup"
    >
      <div
        class="mapping-popup__frame"
        @click.stop="() => {}"
        ref="mappingPopupRef"
      >
        <div class="mapping-popup__header">
          <div class="mapping-popup__header-content">
            <div class="mapping-popup__breadcrumbs">
              <div
                v-for="breadcrumb in breadcrumbs"
                :key="breadcrumb"
                class="mapping-popup__breadcrumbs-item"
                @click="updateScreen(breadcrumb)"
              >
                {{ getBreadcrumbLabel(breadcrumb) }}
              </div>
            </div>
            <div class="mapping-popup__title">
              <Button
                v-if="parent"
                variant="tertiary"
                icon="chevron-left"
                icon-only
                @click="updateScreen(parent)"
              />
              <Heading
                as="h1"
                size="xs"
              >
                {{ title }}
              </Heading>
            </div>
          </div>
          <Button
            variant="tertiary"
            icon="x"
            icon-only
            @click="close"
          />
        </div>
        <div class="mapping-popup__body">
          <Spinner
            v-if="loading"
          />
          <slot
            v-else
            :view="view"
            :edit-data="editData"
            :selected-feed="selectedFeed"
            :selected-sport-label="selectedSportLabel"
            :newly-mapped="newlyMapped"
            :popup-config="popupConfig"
            :mapping-type="mappingType"
          >
            <component
              :is="view"
              :edit-data="editData"
              :selected-feed="selectedFeed"
              :newly-mapped="newlyMapped"
              :popup-config="popupConfig"
              :selected-sport-label="selectedSportLabel"
              :mapping-type="mappingType"
              @change-screen="updateScreen"
            />
          </slot>
        </div>
        <div
          v-if="showFooter"
          class="mapping-popup__footer"
        >
          <Button
            variant="primary"
            @click="close"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { startCase } from 'lodash';
import { createPopupConfig, createBreadcrumbs } from '@/services/helpers/mappings-mapper';
import Heading from '@/components/common/Heading';
import Button from '@/components/common/Button';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    Heading,
    Button,
    Spinner,
  },
  props: {
    screen: {
      type: String,
      required: true,
    },
    screenConfigs: {
      type: Object,
      required: true,
    },
    editData: {
      type: Object,
      required: true,
    },
    selectedFeed: {
      type: String,
      required: true,
    },
    selectedSportLabel: {
      type: String,
      required: true,
    },
    mappingType: {
      type: String,
      required: true,
    },
    newlyMapped: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:screen': {
      type: String,
    },
    close: {
    },
  },
  setup(props, { emit }) {
    const view = computed(() => props.screenConfigs[props.screen].view);
    const label = computed(() => props.screenConfigs[props.screen].label);
    const parent = computed(() => props.screenConfigs[props.screen].parent);
    const disableFooter = computed(() => props.screenConfigs[props.screen].disableFooter);
    const popupConfig = computed(() => createPopupConfig({
      editData: props.editData,
      mappingType: props.mappingType,
      selectedFeed: props.selectedFeed || 'HUDDLE',
    }));
    const breadcrumbs = computed(() => createBreadcrumbs(props.screenConfigs, props.screen));

    const title = computed(() => `${label.value} - ${startCase(props.editData.feed)}`);
    const showFooter = computed(() => !disableFooter.value && props.newlyMapped);
    const getBreadcrumbLabel = (breadcrumb) => props.screenConfigs[breadcrumb].label;

    const updateScreen = (newScreen) => {
      emit('update:screen', newScreen);
    };
    const close = () => {
      emit('close');
    };

    const mappingPopupRef = ref(null);

    onClickOutside(mappingPopupRef, () => {
      close();
    });

    return {
      view,
      label,
      parent,
      popupConfig,
      breadcrumbs,
      title,
      showFooter,
      getBreadcrumbLabel,
      updateScreen,
      close,
      mappingPopupRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.mapping-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize(#000, 0.5);
  z-index: 9999;
}

.mapping-popup__frame {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 740px;
  max-height: 700px;
  padding: 32px 32px 28px;
  border-radius: 4px;
}

.mapping-popup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.mapping-popup__header-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mapping-popup__title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #191414;
}

.mapping-popup__breadcrumbs {
  display: flex;
  user-select: none;
}

.mapping-popup__breadcrumbs-item {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6D6D6D;
  cursor: default;

  &:not(:last-of-type){
    cursor: pointer;

    &:hover {
    }

    &::after {
      content: '>';
      margin: 0 5px;
    }
  }
}

.mapping-popup__body {
  height: 100%;
}

.mapping-popup__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
  gap: 8px;
}
</style>
