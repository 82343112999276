<template>
  <div class="event-mapping-details">
    <MappingItem
      :feed="popupConfig.details.icon"
      :title="popupConfig.details.name"
      :subtitle="popupConfig.details.additionalInfo"
    >
      <Dropdown
        class="more-actions-btn"
        icon="more-dots"
        hide-chevron
        right
        transparent-button
      >
        <DropdownItem
          @click="handleAdditionalActions(mappingActionTypes.COPY_ID)"
          clickable
        >
          <Icon :name="'copy'" />
          <span>Copy ID</span>
        </DropdownItem>
      </Dropdown>
      <div
        :class="['copy-confirmation ', { 'active': copyIdMessageActive }]"
      >
        ID copied to the clipboard
      </div>
    </MappingItem>
    <MappingContainer
      title="Mapped event"
      :subtitle="`Event created from ${sourceFeedName} database`"
    >
      <MappingItem
        :feed="sourceFeed"
        :title="sourceTitle"
        :subtitle="sourceSubtitle"
      >
        <Button
          icon="link-broken"
          @click="() => confirmUnmapping = true"
        >
          Unmap
        </Button>
      </MappingItem>
      <div class="event-mapping-details__instances">
        <MappedItem
          v-if="mappingDetails.region"
          title="Region"
          :mapping-type="mappingSummary.region"
          :subtitle="mappingDetails.region.extRef"
        />
        <MappedItem
          v-if="mappingDetails.competition"
          title="Competition"
          :mapping-type="mappingSummary.competition"
          :subtitle="mappingDetails.competition.extRef"
        />
        <MappedItem
          v-if="mappingDetails.awayCompetitor"
          title="Away Competitor"
          :mapping-type="mappingSummary.awayCompetitor"
          :subtitle="mappingDetails.awayCompetitor.extRef"
        />
        <MappedItem
          v-if="mappingDetails.awaySquad?.length"
          title="Away Squad"
          :mapping-type="mappingSummary.awaySquad"
          :subtitle="mappingDetails.awayCompetitor.extRef"
          :actionable="mappingSummary.awaySquad !== 'EXACT_MATCH'"
          @click="selectAwaySquad"
        />
        <MappedItem
          v-if="mappingDetails.homeCompetitor"
          title="Home Competitor"
          :mapping-type="mappingSummary.homeCompetitor"
          :subtitle="mappingDetails.homeCompetitor.extRef"
        />
        <MappedItem
          v-if="mappingDetails.homeSquad?.length"
          title="Home Squad"
          :mapping-type="mappingSummary.homeSquad"
          :subtitle="mappingDetails.homeCompetitor.extRef"
          :actionable="mappingSummary.homeSquad !== 'EXACT_MATCH'"
          @click="selectHomeSquad"
        />
      </div>
    </MappingContainer>
    <div
      class="unmap-confirmation-popup"
      v-if="confirmUnmapping"
    >
      <div class="header">
        <Heading
          size="xs"
        >
          {{ popupTitle }}
        </Heading>
        <div
          class="icon-button"
          @click="() => confirmUnmapping = false"
        >
          <Icon :name="'x'" />
        </div>
      </div>
      <div>You'll be able to map source again.</div>
      <div class="unmap-buttons">
        <div
          class="unmap-button"
          @click="() => confirmUnmapping = false"
        >
          Cancel
        </div>
        <div
          class="unmap-button confirm"
          @click="confirmAction"
        >
          Yes, unmap
        </div>
      </div>
    </div>
    <div
      class="back-cover"
      v-if="confirmUnmapping"
    />
  </div>
</template>

<script>
import { startCase, find } from 'lodash';
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useStore } from 'vuex';
import formatDate, { DateTypes } from '@/utils/format-date';
import {
  feedTypes,
  mapEventMappingSummary,
  mappingActionTypes,
  copyToClipboard,
} from '@/services/helpers/mappings-mapper';
import Heading from '@/components/common/Heading';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import MappingContainer from '@/components/mapping/common/MappingContainer';
import MappingItem from '@/components/mapping/common/MappingItem';
import MappedItem from '@/components/mapping/common/MappedItem';

export default {
  components: {
    Heading,
    Icon,
    Button,
    Dropdown,
    DropdownItem,
    MappingContainer,
    MappingItem,
    MappedItem,
  },
  props: {
    selectedFeed: {
      type: String,
      required: true,
    },
    popupConfig: {
      type: Object,
      default: null,
    },
    editData: {
      type: Object,
      default: null,
    },
    mappingDetails: {
      type: Object,
      default: null,
    },
    selectedSportLabel: {
      type: String,
      required: true,
    },
    mappingType: {
      type: String,
      required: true,
    },
  },
  emits: {
    changeScreen: {
      type: String,
    },
    selectSquad: {
      type: Object,
    },
    loadMappingDetails: {
    },
    unmapMappingDetails: {
    },
    closePopup: {
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const popupTitle = computed(() => {
      let title = 'Unmap the ';
      switch (props.mappingType) {
      case 'EVENTS_MAPPING':
        title += 'event';
        break;
      case 'COMPETITORS_MAPPING':
        title += 'competitor';
        break;
      case 'REGIONS_MAPPING':
        title += 'region';
        break;
      case 'COMPETITIONS_MAPPING':
        title += 'competition';
        break;
      case 'PLAYERS_MAPPING':
        title += 'player';
        break;
      default:
        break;
      }
      return `${title}?`;
    });
    const mappingSummary = computed(() => mapEventMappingSummary(props.mappingDetails));

    const sourceFeed = computed(() => props.editData.row.source || props.editData.feed);
    const sourceFeedName = computed(() => startCase(sourceFeed.value));
    const sourceTitle = computed(() => find(props.editData.row?.mappings, { feed: sourceFeed.value })?.extRef);
    const sourceSubtitle = computed(() => {
      const competition = `Competition: ${props.editData.row.competitionName}`;
      const date = formatDate(props.editData.row.startsAt, DateTypes.DATE_TIME_SUGGESTED_MAPPING, sourceFeed.value === feedTypes.HUDDLE);
      return `${competition} • ${date}`;
    });

    const selectAwaySquad = () => {
      emit('selectSquad', {
        competitor: props.mappingDetails.awayCompetitor,
        players: props.mappingDetails.awaySquad,
      });
    };
    const selectHomeSquad = () => {
      emit('selectSquad', {
        competitor: props.mappingDetails.homeCompetitor,
        players: props.mappingDetails.homeSquad,
      });
    };

    const confirmUnmapping = ref(false);

    const confirmAction = () => {
      const payload = {
        id: props.mappingDetails.event.intId,
        mappingId: props.mappingDetails.event.mappingId,
        sport: props.selectedSportLabel,
        mappingType: 'EVENTS_MAPPING',
      };

      store.dispatch('unmapMapping', payload)
        .then(() => {
          const message = 'Event has been successfully unmapped';
          store.dispatch('addNotification', {
            message,
            type: 'success',
            duration: 5000,
          });
          emit('unmapMappingDetails');
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('addNotification', {
            message: 'Error while unmapping the event',
            type: 'error',
            duration: 5000,
          });
          emit('loadMappingDetails');
        })
        .finally(() => {
          confirmUnmapping.value = false;
        });
    };

    const copyIdMessageActive = ref(false);

    const handleAdditionalActions = (action) => {
      const { COPY_ID } = mappingActionTypes;
      switch (action) {
      case COPY_ID:
        copyToClipboard(props.editData.row.id);
        copyIdMessageActive.value = true;
        const timeout = setTimeout(() => {
          copyIdMessageActive.value = false;
          clearTimeout(timeout);
        }, 3000);
        break;
      default:
        break;
      }
    };

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') {
        emit('closePopup');
      }
    };
    onMounted(() => {
      document.addEventListener('keydown', onEscEventListener);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('keydown', onEscEventListener);
    });

    return {
      popupTitle,
      mappingActionTypes,
      mappingSummary,
      sourceFeed,
      sourceFeedName,
      sourceTitle,
      sourceSubtitle,
      selectAwaySquad,
      selectHomeSquad,
      confirmUnmapping,
      confirmAction,
      copyIdMessageActive,
      handleAdditionalActions,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-mapping-details {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .button,
  .dropdown-item {
    stroke: #000;
  }

  .dropdown-item {
    display: flex;
    gap: 8px;
  }

  .copy-confirmation {
        display: none;
        min-width: 170px;
        text-align: center;
        background-color: #191414;
        color: #FFF;
        font-size: 12px;
        font-family: 'Rubik';
        line-height: 14px;
        padding: 8px 12px;
        border-radius: 4px;
        position: absolute;
        right: -30px;
        bottom: -10px;

        &.active {
          display: inline-block;
        }
      }
}

.unmap-confirmation-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 320px;
    height: 160px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    background: #FFFFFF;
    z-index: 103;
    padding: 16px;

    .header {
      width: 100%;
      height: 50px;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .icon-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 33px;
        width: 33px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;

          svg {
            stroke: #6D6D6D;
            fill: #6D6D6D;
          }
        }
      }
    }

    .body {
      height: 17px;
      line-height: 17px;
    }

    .unmap-buttons {
      height: 33px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;

      .unmap-button {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 12px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &.confirm {
          margin-left: 16px;
          color: #ffffff;
          background-color: #003C3C;
        }
      }
    }
  }

  .back-cover {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 102;
    left: 0;
    top: 0;
  }
</style>
