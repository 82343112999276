<template>
  <div class="squad-mapping-details">
    <MappingItem
      :feed="popupConfig.details.icon"
      :title="popupConfig.details.name"
      :subtitle="popupConfig.details.additionalInfo"
    >
      <Dropdown
        class="more-actions-btn"
        icon="more-dots"
        hide-chevron
        right
        transparent-button
      >
        <DropdownItem
          @click="handleAdditionalActions(mappingActionTypes.COPY_ID)"
          clickable
        >
          <Icon :name="'copy'" />
          <span>Copy ID</span>
        </DropdownItem>
      </Dropdown>
      <div
        :class="['copy-confirmation ', { 'active': copyIdMessageActive }]"
      >
        ID copied to the clipboard
      </div>
    </MappingItem>
    <MappingContainer
      :title="selectedSquad.competitor.extRef"
    >
      <div
        v-if="unmappedPlayers.length"
        class="squad-mapping-details__instances"
      >
        <MappedItem
          v-for="player in unmappedPlayers"
          :key="player"
          :mapping-type="player.mappingType"
          :subtitle="formatPlayerName(player.extRef)"
          actionable
          @click="selectPlayer(player)"
        />
      </div>
      <span
        class="squad-mapping-details__separator"
      />
      <div class="squad-mapping-details__instances">
        <MappedItem
          v-for="player in mappedPlayers"
          :key="player"
          :mapping-type="player.mappingType"
          :subtitle="formatPlayerName(player.extRef)"
        />
      </div>
    </MappingContainer>
  </div>
</template>

<script>
import { filter } from 'lodash';
import {
  ref, computed, onMounted, onBeforeUnmount,
} from 'vue';
import { mappingActionTypes, copyToClipboard } from '@/services/helpers/mappings-mapper';
import Icon from '@/components/common/Icon';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import MappingContainer from '@/components/mapping/common/MappingContainer';
import MappingItem from '@/components/mapping/common/MappingItem';
import MappedItem from '@/components/mapping/common/MappedItem';

export default {
  components: {
    Icon,
    Dropdown,
    DropdownItem,
    MappingContainer,
    MappingItem,
    MappedItem,
  },
  props: {
    popupConfig: {
      type: Object,
      default: null,
    },
    selectedSquad: {
      type: Object,
      required: true,
    },
    editData: {
      type: Object,
      default: null,
    },
  },
  emits: {
    selectPlayer: {
    },
    closePopup: {
    },
  },
  setup(props, { emit }) {
    const unmappedPlayers = computed(() => filter(props.selectedSquad.players, ({ mappingType }) => mappingType !== 'EXACT_MATCH'));
    const mappedPlayers = computed(() => filter(props.selectedSquad.players, { mappingType: 'EXACT_MATCH' }));

    const formatPlayerName = (name) => name.split(',').reverse().join(' ');

    const selectPlayer = (player) => {
      emit('selectPlayer', player);
    };

    const copyIdMessageActive = ref(false);

    const handleAdditionalActions = (action) => {
      const { COPY_ID } = mappingActionTypes;
      switch (action) {
      case COPY_ID:
        copyToClipboard(props.editData.row.id);
        copyIdMessageActive.value = true;
        const timeout = setTimeout(() => {
          copyIdMessageActive.value = false;
          clearTimeout(timeout);
        }, 3000);
        break;
      default:
        break;
      }
    };

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') {
        emit('closePopup');
      }
    };
    onMounted(() => {
      document.addEventListener('keydown', onEscEventListener);
    });
    onBeforeUnmount(() => {
      document.removeEventListener('keydown', onEscEventListener);
    });

    return {
      unmappedPlayers,
      mappedPlayers,
      mappingActionTypes,
      formatPlayerName,
      selectPlayer,
      copyIdMessageActive,
      handleAdditionalActions,
    };
  },
};
</script>

<style lang="scss" scoped>
.squad-mapping-details {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .dropdown-item {
    stroke: #000;
  }

  .dropdown-item {
    display: flex;
    gap: 8px;
  }

  .copy-confirmation {
        display: none;
        min-width: 170px;
        text-align: center;
        background-color: #191414;
        color: #FFF;
        font-size: 12px;
        font-family: 'Rubik';
        line-height: 14px;
        padding: 8px 12px;
        border-radius: 4px;
        position: absolute;
        right: -30px;
        bottom: -10px;

        &.active {
          display: inline-block;
        }
      }
}

.squad-mapping-details__separator {
  display: block;
  background: #F0F0F0;
  width: 100%;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
}

.squad-mapping-details__instances {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
