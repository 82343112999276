import EventMappingSuggestions from './EventMappingSuggestions';
import EventMappingDetails from './EventMappingDetails';
import SquadMappingDetails from './SquadMappingDetails';
import PlayerMappingSuggestions from './PlayerMappingSuggestions';

export const screenTypes = {
  EVENT_SUGGESTIONS: 'EVENT_SUGGESTIONS',
  EVENT_DETAILS: 'EVENT_DETAILS',
  SQUAD_DETAILS: 'SQUAD_DETAILS',
  PLAYER_SUGGESTIONS: 'PLAYER_SUGGESTIONS',
};

export const screens = {
  [screenTypes.EVENT_SUGGESTIONS]: {
    view: EventMappingSuggestions,
    label: 'Event mapping',
    disableFooter: true,
  },
  [screenTypes.EVENT_DETAILS]: {
    view: EventMappingDetails,
    label: 'Event mapping',
  },
  [screenTypes.SQUAD_DETAILS]: {
    view: SquadMappingDetails,
    label: 'Squad mapping',
    parent: screenTypes.EVENT_DETAILS,
  },
  [screenTypes.PLAYER_SUGGESTIONS]: {
    view: PlayerMappingSuggestions,
    label: 'Player mapping',
    parent: screenTypes.SQUAD_DETAILS,
    disableFooter: true,
  },
};
